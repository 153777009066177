import React, { Component } from "react";
import Header from "./Components/Header/Header";
import Main from "./Components/Main/Main";

class App extends Component {
  state = {};

  render() {
    return (
      <>
        <Header />
        <Main />
      </>
    );
  }
}

export default App;
