// React
import React, { Component } from "react";
// React
// CSS
import styles from "./RootPage.module.css";
// CSS
// Images
import LeafIcon from "../../Icons/icons8-leaf-100.png";
// Images
class RootPage extends Component {
  state = {};
  componentDidMount() {}
  render() {
    return (
      <main className={styles.mainRoot}>
        <h3>پیتزای خودت رو بساز</h3>

        <div className={styles.pizzaContainer}>
          <div className={styles.innerPizza}>
            <div className={styles.bread}>
              <div className={styles.susages}>
                <div className={`${styles.sus}`} id={styles.susOne}>
                  {" "}
                  <div className={`${styles.egg}`}></div>
                  <div className={`${styles.eggThree}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susTwo}>
                  {" "}
                  <div className={`${styles.eggTwo}`}></div>
                  <div className={`${styles.eggThree}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susThree}>
                  {" "}
                  <div className={`${styles.eggThree}`}></div>
                  <div className={`${styles.egg}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susFour}>
                  {" "}
                  <div className={`${styles.egg}`}></div>
                  <div className={`${styles.eggThree}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susFive}>
                  {" "}
                  <div className={`${styles.eggThree}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susSix}>
                  {" "}
                  <div className={`${styles.eggThree}`}></div>
                  <div className={`${styles.eggTwo}`}></div>
                </div>
                <div className={`${styles.sus} `} id={styles.susSeven}>
                  {" "}
                  <div className={`${styles.egg}`}></div>
                  <div className={`${styles.eggTwo}`}></div>
                </div>
              </div>

              <img
                src={LeafIcon}
                className={styles.leafIcon}
                alt="Leaf ic"
                id={styles.leafOne}
              />
              <img
                src={LeafIcon}
                className={styles.leafIcon}
                alt="Leaf ic"
                id={styles.leafTwo}
              />
              <img
                src={LeafIcon}
                className={styles.leafIcon}
                alt="Leaf ic"
                id={styles.leafThree}
              />
              <img
                src={LeafIcon}
                className={styles.leafIcon}
                alt="Leaf ic"
                id={styles.leafFour}
              />
              <img
                src={LeafIcon}
                className={styles.leafIcon}
                alt="Leaf ic"
                id={styles.leafFive}
              />
              <div className={styles.zeytoonHa}>
                <div className={styles.zeytoonContainer} id={styles.zeytoonOne}>
                  <div className={styles.zeytoon}></div>
                </div>
                <div className={styles.zeytoonContainer} id={styles.zeytoonTwo}>
                  <div className={styles.zeytoon}></div>
                </div>
                <div
                  className={styles.zeytoonContainer}
                  id={styles.zeytoonThree}
                >
                  <div className={styles.zeytoon}></div>
                </div>
                <div
                  className={styles.zeytoonContainer}
                  id={styles.zeytoonFour}
                >
                  <div className={styles.zeytoon}></div>
                </div>
                <div
                  className={styles.zeytoonContainer}
                  id={styles.zeytoonFive}
                >
                  <div className={styles.zeytoon}></div>
                </div>
              </div>
              <div className={styles.mushroomsContainer}>
                <img alt=" " id={styles.mushroomOne} />
                <img alt=" " id={styles.mushroomTwo} />
                <img alt=" " id={styles.mushroomThree} />
                <img alt=" " id={styles.mushroomFour} />
                <img alt=" " id={styles.mushroomFive} />
              </div>
              <div className={styles.cheaseContainer}>
                <div className={styles.cheasTwo} id={styles.IDcheaseTen}></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseEleven}
                ></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseTwoelve}
                ></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseTherteen}
                ></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseFourteen}
                ></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseFifteen}
                ></div>
                <div
                  className={styles.cheasTwo}
                  id={styles.IDcheaseSixteen}
                ></div>
              </div>
              <div className={styles.vegContainer}>
                <div className={styles.veg} id={styles.vegOne}></div>
                <div className={styles.veg} id={styles.vegTwo}></div>
                <div className={styles.veg} id={styles.vegThree}></div>
                <div className={styles.veg} id={styles.vegFour}></div>
              </div>
            </div>
          </div>
        </div>
        <img src="" alt="" />
      </main>
    );
  }
}

export default RootPage;
