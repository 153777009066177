// React
import React, { Component } from "react";
// React
// Images
// Images
// Modules
// Modules
// CSS
import styles from "./MyPizzas.module.css";
// CSS

class MyPizzas extends Component {
  constructor(props) {
    super(props);
    this.beforeFoodContainerRef = React.createRef();
    this.americaPizza = JSON.parse(
      localStorage.getItem("americaPizza") || "[]"
    );
    this.italianPizza = JSON.parse(
      localStorage.getItem("italianPizza") || "[]"
    );
    this.drinksItems = JSON.parse(localStorage.getItem("drinksItems") || "[]");
    this.beforeFood = JSON.parse(localStorage.getItem("beforeFood") || "[]");
  }
  state = {};
  componentDidMount() {
    document.getElementsByTagName("body")[0].style.overflowY = "scroll";
  }
  componentWillUnmount() {
    document.getElementsByTagName("body")[0].style.overflowY = "hidden";
  }
  render() {
    return (
      <main>
        <div className={styles.cartItemsContainer}>
          <b>پیتزای خودساخته</b>
          موردی وجود ندارد
          <div className={styles.customHr}></div>
          <b>پیش غذا</b>
          <div className={styles.beforeFood}>
            {this.beforeFood.length === 0
              ? "هنوز چیزی انتخاب نشده"
              : this.beforeFood.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.beforeFoodItem}
                      ref={this.beforeFoodContainerRef}
                    >
                      <div className={styles.left}>
                        <img src={item.picture} alt={`${item.name} - Image`} />
                      </div>
                      <div className={styles.right}>
                        <h1 className={styles.itemName}>{item.name}</h1>
                        <h1 className={styles.itemPrice}>{item.price}</h1>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className={styles.customHr}></div>
          <b>پیتزای آمریکایی</b>
          <div className={styles.americaPizza}>
            {this.americaPizza.length === 0
              ? "هنوز چیزی انتخاب نشده"
              : this.americaPizza.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.beforeFoodItem}
                      ref={this.beforeFoodContainerRef}
                    >
                      <div className={styles.left}>
                        <img src={item.picture} alt={`${item.name} - Image`} />
                      </div>
                      <div className={styles.right}>
                        <h1 className={styles.itemName}>{item.name}</h1>
                        <h1 className={styles.itemPrice}>{item.price}</h1>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className={styles.customHr}></div>
          <b>پیتزای ایتالیایی</b>
          <div className={styles.italianPizza}>
            {this.italianPizza.length === 0
              ? "هنوز چیزی انتخاب نشده"
              : this.italianPizza.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.beforeFoodItem}
                      ref={this.beforeFoodContainerRef}
                    >
                      <div className={styles.left}>
                        <img src={item.picture} alt={`${item.name} - Image`} />
                      </div>
                      <div className={styles.right}>
                        <h1 className={styles.itemName}>{item.name}</h1>
                        <h1 className={styles.itemPrice}>{item.price}</h1>
                      </div>
                    </div>
                  );
                })}
          </div>
          <div className={styles.customHr}></div>
          <b>نوشیدنی</b>
          <div className={styles.drinksItems}>
            {this.drinksItems.length === 0
              ? "هنوز چیزی انتخاب نشده"
              : this.drinksItems.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={styles.beforeFoodItem}
                      ref={this.beforeFoodContainerRef}
                    >
                      <div className={styles.left}>
                        <img src={item.picture} alt={`${item.name} - Image`} />
                      </div>
                      <div className={styles.right}>
                        <h1 className={styles.itemName}>{item.name}</h1>
                        <h1 className={styles.itemPrice}>{item.price}</h1>
                      </div>
                    </div>
                  );
                })}
          </div>
        </div>
      </main>
    );
  }
}
export default MyPizzas;
