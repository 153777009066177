// React
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AmericaPizza from "../../Pages/AmericaPizza/AmericaPizza";
import BeforeFood from "../../Pages/BeforeFood/BeforeFood";
import Drinks from "../../Pages/Drinks/Drinks";
import ItalianPizza from "../../Pages/ItalianPizza/ItalianPizza";
import MyPizzaMaker from "../../Pages/MyPizzaMaker/MyPizzaMaker";
import MyPizzas from "../../Pages/MyPizzas/MyPizzas";
import RootPage from "../../Pages/RootPage/RootPage";
// React
// Css
// Css
class Main extends Component {
  state = {};
  render() {
    return (
      <>
        <Routes>
          <Route path="/" element={<RootPage />} />
          <Route path="/my-pizza" element={<MyPizzaMaker />} />
          <Route path="/america-pizza" element={<AmericaPizza />} />
          <Route path="/italian-pizza" element={<ItalianPizza />} />
          <Route path="/before-food" element={<BeforeFood />} />
          <Route path="/drinks" element={<Drinks />} />
          <Route path="/contact-us" element={<RootPage />} />
          <Route path="/my-pizzas" element={<MyPizzas />} />
          <Route path="/about-us" element={<RootPage />} />
        </Routes>
      </>
    );
  }
}

export default Main;
