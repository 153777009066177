// React
import React, { Component } from "react";
import { Link } from "react-router-dom";
// React
// css
import styles from "./headerHelper.module.css";
// css
class HeaderHelper extends Component {
  state = {};
  render() {
    return (
      <div className={styles.headerHleper}>
        <ul>
          <li>
            <Link
              to="/america-pizza"
              className={this.props.americaBold && styles.bold}
            >
              پیتزای&nbsp;آمریکایی
            </Link>
          </li>
          <li>
            <Link
              to="/italian-pizza"
              className={this.props.italianBold && styles.bold}
            >
              پیتزا&nbsp;ایتالیایی
            </Link>
          </li>

          <li>
            <Link
              to="/before-food"
              className={this.props.beforeFoodBold && styles.bold}
            >
              پیش&nbsp;غذا
            </Link>
          </li>
          <li>
            <Link to="/drinks" className={this.props.drinksBold && styles.bold}>
              نوشیدنی
            </Link>
          </li>
        </ul>
      </div>
    );
  }
}

export default HeaderHelper;
