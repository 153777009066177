// React
import React, { Component } from "react";
import HeaderHelper from "../../Components/HraderHelper/HeaderHelper";
// React
// Css
import styles from "./AmericaPizza.module.css";
// Css
// Pictres
import charMaze from "../../Images/pizza/america/4maze.png";
import chonoSteyk from "../../Images/pizza/america/chano-steyk.png";
import dorSosis from "../../Images/pizza/america/dor-sussis.png";
import gooshOGhrach from "../../Images/pizza/america/goosht-o-gharch.png";
import hawaei from "../../Images/pizza/america/hawaee.png";
import jambon from "../../Images/pizza/america/jambon.png";
import makhsoos from "../../Images/pizza/america/makhsoos.png";
import margarita from "../../Images/pizza/america/margarita.png";
import mexico from "../../Images/pizza/america/mexico.png";
import peperoni from "../../Images/pizza/america/peproni.png";
import sabzijat from "../../Images/pizza/america/sabzijat.png";
import sisili from "../../Images/pizza/america/silisi.png";
/////////////////////////////////////////////////
/////////////////////////////////////////////////
// Pictres
// Modules
import { addItemToLocalStorage } from "../../Components/MakeItemReadyForLocalStorage/MakeItemReadyForLocalStorage";
// Modules

class AmericaPizza extends Component {
  constructor(props) {
    super(props);
    this.americaPizzaRef = React.createRef();
  }
  state = {
    americaPizza: [
      {
        id: "a-1",
        name: "دورسوسیسی مخصوص",
        about: "ژامبون ، قارچ ، استیک ، پنیر موزارلا ، سالامی",
        picture: dorSosis,
        price: 50000,
      },
      {
        id: "a-2",
        name: "پیتزا مکزیکانو",
        about:
          "گوشت قلقلی ، ذرت ، سس مکزیکی ، پیاز ، پنیر موزارلا ، هالوپینو ، فلفل دلمه‌ای ، پن آمریکایی ، زیتون سیاه ، زیتون سیاه",
        picture: mexico,
        price: 90000,
      },
      {
        id: "a-3",
        name: "پیتزا مارگاریتا",
        about: "پنیر موزارلا ، سس گوجه فرنگی ، گوجه گیلاسی ، پن آمریکایی",
        picture: margarita,
        price: 100000,
      },
      {
        id: "a-4",
        name: "پیتزا پپرونی",
        about: "پپرونی ، سس مکزیکی ، پن آمریکایی ، پنیر موزارلا",
        picture: peperoni,
        price: 80000,
      },
      {
        id: "a-5",
        name: "پیتزا چهار مزه",
        about:
          "سینه مرغ گریل شده ، گوجه گیلاسی ، زیتون سبز ، قارچ ، سس گوجه فرنگی ، پیاز ، استیک ، فلفل دلمه‌ای ، اسفناج ، ذرت ، ژامبون بوقلمون ، زیتون سیاه ، زیتون سیاه ، پن آمریکایی",
        picture: charMaze,
        price: 150000,
      },
      {
        id: "a-6",
        name: "پیتزا چانو (استیک)",
        about:
          "سس سیر ، پنیر موزارلا ، قارچ ، پنیر بلوچيز ، سس گوجه فرنگی ، چدار ، فیله گوساله ، استیک ، پن آمریکایی",
        picture: chonoSteyk,
        price: 130000,
      },
      {
        id: "a-7",
        name: "پیتزا ژامبون",
        about:
          "ژامبون بوقلمون ، ژامبون ، سس گوجه فرنگی ، پنیر موزارلا ، قارچ ، چدار ، اورگانو ، پن آمریکایی",
        picture: jambon,
        price: 140000,
      },
      {
        id: "a-8",
        name: "پیتزا گوشت و قارچ",
        about: "پن آمریکایی ، پیاز ، قارچ ، گوشت قلقلی ، سالامی ، پنیر موزارلا",
        picture: gooshOGhrach,
        price: 70000,
      },
      {
        id: "a-9",
        name: "پیتزا مخصوص",
        about:
          "سس گوجه فرنگی ، ژامبون ، پنیر گودا ، پنیر موزارلا ، سالامی ، چدار ، استیک ، قارچ ، پنیر بلوچيز ، پنیر پارمزان ، پن آمریکایی",
        picture: makhsoos,
        price: 120000,
      },
      {
        id: "a-10",
        name: "سیسیلی",
        about:
          "پپرونی ، زیتون سیاه ، پنیر موزارلا ، فلفل دلمه‌ای ، ژامبون ، سوسیس پنیری ، پن آمریکایی",
        picture: sisili,
        price: 150000,
      },
      {
        id: "a-11",
        name: "هاوایی 1",
        about: "پن آمریکایی ، پنیر موزارلا ، ژامبون بوقلمون ، آناناس",
        picture: hawaei,
        price: 200000,
      },
      {
        id: "a-12",
        name: "پیتزا سبزیجات",
        about:
          "کدو ، پنیر موزارلا ، گوجه گیلاسی ، قارچ ، اسفناج ، بادمجان ، سس گوجه فرنگی ، پیاز ، زیتون سبز ، کاپری ، پن آمریکایی",
        picture: sabzijat,
        price: 215000,
      },
    ],
    cartItemsForAmericaPizza: [],
  };
  componentDidMount() {
    setTimeout(() => {
      this.americaPizzaRef.current.style.opacity = 1;
      this.americaPizzaRef.current.style.transform = "scale(1)";
    }, 200);
  }
  render() {
    return (
      <main className={styles.americaPizzaContainer}>
        <HeaderHelper americaBold={true} />
        <div className={styles.americaPizza} ref={this.americaPizzaRef}>
          <div className={styles.pizzaContainer}>
            {this.state.americaPizza.map((item) => {
              return (
                <div key={item.id} className={styles.pizzaModel}>
                  <div className={styles.left}>
                    <img src={item.picture} alt={item.name + "image"} />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.pizzaName}>{item.name}</div>
                    <div className={styles.innerPizza}>
                      <div>مواد تشکیل دهنده</div>
                      <div className={styles.innerContent}>{item.about}</div>
                      <div> قیمت : {item.price} تومان</div>

                      <button
                        className={styles.addToCart}
                        onClick={() => {
                          addItemToLocalStorage("americaPizza", item);
                        }}
                      >
                        افزودن به سبد خرید
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </main>
    );
  }
}

export default AmericaPizza;
