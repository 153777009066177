// React
import React, { Component } from "react";
// React
// css
import styles from "./MyPizzaMaker.module.css";
// css
// Images
// modules
import ReactTooltip from "react-tooltip";
// import { localStorageChecker } from "../../Components/MakeItemReadyForLocalStorage/MakeItemReadyForLocalStorage";
// modules
//
// React Icons
import { BsClockFill } from "react-icons/bs";
import { GiRollingEnergy } from "react-icons/gi";
import { BiArrowFromBottom } from "react-icons/bi";
import { BiArrowToBottom } from "react-icons/bi";
// React Icons
///
// Images
import {
  b1,
  b2,
  b3,
  //
  c1,
  c2,
  c3,
  //
  s1,
  s2,
  s3,
  s4,
  s5,
  //
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  m8,
  m9,
  //
  v1,
  v2,
  v3,
  v4,
  v5,
  v6,
  v7,
  v8,
  v9,
  v10,
  v11,
  v12,
  v13,
  v14,
} from "../../Components/Images/Images";
///
// Images

class MyPizzaMaker extends Component {
  state = {
    breads: [
      {
        id: "b-1",
        name: "نان ساده",
        image: b1,
        cal: 100,
        cookTime: 3,
      },
      {
        id: "b-2",
        name: "نان مخصوص",
        image: b2,
        cal: 150,
        cookTime: 5,
      },
      {
        id: "b-3",
        name: "نان دور سوسیس",
        image: b3,
        cal: 200,
        cookTime: 6,
      },
    ],
    sos: [
      {
        id: "s-1",
        name: "سس مایونز",
        image: s1,
        cal: 100,
      },
      {
        id: "s-2",
        name: "سس کلم",
        image: s2,
        cal: 70,
      },
      {
        id: "s-3",
        name: "سس باربیکیو",
        image: s3,
        cal: 90,
      },
      {
        id: "s-4",
        name: "سس تند",
        image: s4,
        cal: 20,
      },
      {
        id: "s-5",
        name: "سس کچاپ",
        image: s5,
        cal: 50,
      },
    ],
    chease: [
      {
        id: "c-1",
        name: "پنیر چدار",
        image: c1,
        cal: 170,
        cookTime: 2,
      },
      {
        id: "c-2",
        name: "پنیر ساده",
        image: c2,
        cal: 370,
        cookTime: 1,
      },
      {
        id: "c-3",
        name: "پنیر موزارلا",
        image: c3,
        cal: 250,
        cookTime: 4,
      },
    ],
    meats: [
      {
        id: "m-1",
        name: "ژامبون  %90 گوشت",
        image: m1,
        cal: 120,
        cookTime: 5,
      },
      {
        id: "m-2",
        name: "گوشت بیکن",
        image: m2,
        cal: 130,
        cookTime: 10,
      },
      {
        id: "m-3",
        name: "گوشت گریل",
        image: m3,
        cal: 150,
        cookTime: 6,
      },
      {
        id: "m-4",
        name: "گوشت تکه ای ویژه",
        image: m4,
        cal: 190,
        cookTime: 8,
      },
      {
        id: "m-5",
        name: "کراکوف",
        image: m5,
        cal: 140,
        cookTime: 6,
      },
      {
        id: "m-6",
        name: "سوسیس پنیری",
        image: m6,
        cal: 410,
        cookTime: 5,
      },
      {
        id: "m-7",
        name: "چوریتسو",
        image: m7,
        cal: 160,
        cookTime: 4,
      },
      {
        id: "m-8",
        name: "گوشت بوقلمون",
        image: m8,
        cal: 200,
        cookTime: 7,
      },
      {
        id: "m-9",
        name: "گوشت مرغ",
        image: m9,
        cal: 180,
        cookTime: 10,
      },
    ],
    vegtables: [
      {
        id: "v-1",
        name: "بادمجان کبابی",
        image: v1,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-2",
        name: "بادمجان ساده",
        image: v2,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-3",
        name: "پنیر آبی",
        image: v3,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-4",
        name: "کلم بروکلی",
        image: v4,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-5",
        name: "ذرت",
        image: v5,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-6",
        name: "فلفل دلمه",
        image: v6,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-7",
        name: "اسفناج",
        image: v7,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-8",
        name: "گوجه خشک",
        image: v8,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-9",
        name: "هالوپینو ( تند )",
        image: v9,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-10",
        name: "قارچ",
        image: v10,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-11",
        name: "پیاز",
        image: v11,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-12",
        name: "پنیر پستو",
        image: v12,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-13",
        name: "گوجه",
        image: v13,
        cal: 10,
        cookTime: 5,
      },
      {
        id: "v-14",
        name: "زیتون سبز",
        image: v14,
        cal: 10,
        cookTime: 5,
      },
    ],
    cookTime: 0,
    index: 1,
    //
    selectedBread: 1,
    selectedSos: 1,
    selectedMeat: 1,
    selectedChease: 1,
    //
    breadCal: 100,
    sosCal: 0,
    sussageCal: 0,
    meatCal: 0,
    cheaseCal: 0,
    vegtableCal: 0,

    //
    breadCookTime: 3,
    cheaseCookTime: 0,
    meatCookTime: 0,
    vegtableCookTime: 0,
    selectedCustomPizza: {},
  };
  mapHelper = [
    "",
    this.state.breads,
    this.state.sos,
    this.state.chease,
    this.state.meats,
    this.state.vegtables,
  ];
  helperArray = ["", "bread", "sos", "chease", "meat", "veg"];
  helpAlertArray = ["نان", "سس", "پنیر", "گوشت", "سبزیجات"];
  helpForSelectingAlert = [
    "",
    this.state.breadCal,
    this.state.sosCal,
    this.state.cheaseCal,
    this.state.meatCal,
    this.state.vegtableCal,
  ];
  templateMadeObject = {
    breadName: "",
    sosName: "",
    cheaseName: "",
    meatName: "",
    vegName: "",
    breadImage: "",
    sosImage: "",
    cheaseImage: "",
    meatImage: "",
    vegImage: "",
  };
  objectToStoreInLocalStorage = [];
  bgChangerAndHelper = (id) => {
    const selectedIndexofSelectedArray = this.mapHelper[
      this.state.index
    ].findIndex((item) => {
      return item.id === id;
    });
    const selectedItemArray = this.mapHelper[this.state.index];
    const selectedItem = selectedItemArray[selectedIndexofSelectedArray];

    if (this.state.index === 1) {
      this.setState({
        breadCal: selectedItem.cal,
        breadCookTime: selectedItem.cookTime,
      });
      this.templateMadeObject.breadName = selectedItem.name;
      this.templateMadeObject.breadImage = selectedItem.image;
    } else if (this.state.index === 2) {
      this.setState({
        sosCal: selectedItem.cal,
      });
      this.templateMadeObject.sosName = selectedItem.name;
      this.templateMadeObject.sosImage = selectedItem.image;
    } else if (this.state.index === 3) {
      this.setState({
        cheaseCal: selectedItem.cal,
        cheaseCookTime: selectedItem.cookTime,
      });
      this.templateMadeObject.cheaseName = selectedItem.name;
      this.templateMadeObject.cheaseImage = selectedItem.image;
    } else if (this.state.index === 4) {
      this.setState({
        meatCal: selectedItem.cal,
        meatCookTime: selectedItem.cookTime,
      });
      this.templateMadeObject.meatName = selectedItem.name;
      this.templateMadeObject.meatImage = selectedItem.image;
    } else if (this.state.index === 5) {
      this.setState({
        vegtableCal: selectedItem.cal,
        vegtableCookTime: selectedItem.cookTime,
      });
      this.templateMadeObject.vegName = selectedItem.name;
      this.templateMadeObject.vegImage = selectedItem.image;
      this.objectToStoreInLocalStorage.push(this.templateMadeObject);
    } else {
      return;
    }

    return { selectedItem, selectedIndexofSelectedArray };
  };
  componentDidMount() {
    this.setState({
      energy: 0,
      cookTime: 0,
    });
  }
  componentDidUpdate() {
    ReactTooltip.rebuild();
    <ReactTooltip />;
  }
  keyMaker = () => {
    const nowDate = new Date();
    const customKey = `${nowDate.getDay()}-${nowDate.getHours()}-${nowDate.getMinutes()}-${nowDate.getMinutes()}-${nowDate.getMilliseconds()}-${Math.random()}`;
    return customKey;
  };
  render() {
    return (
      <main className={styles.MyPizzaMaker}>
        <div className={styles.left}>
          <div className={styles.topLeft}>
            <GiRollingEnergy data-tip="انرژی" />
            <ReactTooltip />
          </div>
          <div className={styles.bottomLeft}>
            <BsClockFill data-tip="زمان پخت" />{" "}
          </div>
          <div className={styles.topRight}>
            {this.state.breadCal +
              this.state.sosCal +
              this.state.cheaseCal +
              this.state.meatCal}
            Kcal
          </div>
          <div className={styles.bottomRight}>
            <span>دقیقه</span>
            {this.state.meatCookTime +
              this.state.breadCookTime +
              this.state.cheaseCookTime}
          </div>
        </div>
        <div className={styles.mid}>
          <div className={styles.top}>
            <div className={styles.contentsOfPizza}>
              <div className={styles.bread} id="bread">
                <div className={styles.sos} id="sos"></div>
                <div className={styles.susage} id="susage"></div>
                <div className={styles.chease} id="chease"></div>
                <div className={styles.veg} id="veg"></div>
                <div className={styles.meat} id="meat"></div>
              </div>
            </div>
          </div>
          <div className={styles.bottom}>
            <ReactTooltip />
            {}
            {this.mapHelper[this.state.index].map((item) => {
              return (
                <img
                  className={styles.bottomImage}
                  key={item.id}
                  src={item.image}
                  alt={item.id}
                  data-tip={item.name}
                  onClick={() => {
                    const { selectedItem } = this.bgChangerAndHelper(item.id);
                    const selectedContainer = document.getElementById(
                      `${this.helperArray[this.state.index]}`
                    );
                    return (selectedContainer.style.backgroundImage = `url("${selectedItem.image}")`);
                  }}
                />
              );
            })}
          </div>
        </div>

        <div className={styles.right}>
          <button
            onClick={() => {
              return this.setState({ index: this.state.index - 1 });
            }}
            style={{ display: `${this.state.index > 1 ? "flex" : "none"}` }}
          >
            <BiArrowFromBottom />
            مرحله قبل
          </button>
          <button className={styles.breadBtn}></button>
          <button className={styles.sosBtn}></button>
          <button className={styles.cheaseBtn}></button>
          <button className={styles.meatBtn}></button>
          {this.state.index === 5 ? (
            <button
              className={styles.addCustomPizzToCart}
              onClick={() => {
                if (this.state.index === 5) {
                  if (this.state.vegtableCal === 0) {
                    alert("سبزیجات  رو انتخاب کن داداش !");
                    return this.setState({ index: 5 });
                  } else {
                    return;
                    // localStorageChecker(
                    //   "myCustomPizza",
                    //   this.objectToStoreInLocalStorage
                    // );
                  }
                }
              }}
            >
              افزودن&nbsp;پیتزای دلخواه&nbsp;به&nbsp;سبد&nbsp;خرید
            </button>
          ) : (
            <button
              onClick={() => {
                if (this.state.index === 1) {
                  if (this.state.breadCal === 0) {
                    alert("نون رو انتخاب کن داداش !");
                    this.setState({ index: 1 });
                  } else {
                    return this.setState({ index: this.state.index + 1 });
                  }
                } else if (this.state.index === 2) {
                  if (this.state.sosCal === 0) {
                    alert("سس رو انتخاب کن داداش !");
                    this.setState({ index: 2 });
                  } else {
                    return this.setState({ index: this.state.index + 1 });
                  }
                } else if (this.state.index === 3) {
                  if (this.state.cheaseCal === 0) {
                    alert("پنیر رو انتخاب کن داداش !");
                    this.setState({ index: 3 });
                  } else {
                    return this.setState({ index: this.state.index + 1 });
                  }
                } else if (this.state.index === 4) {
                  if (this.state.meatCal === 0) {
                    alert("سوسیس یا گوشت رو انتخاب کن داداش !");
                    this.setState({ index: 4 });
                  } else {
                    return this.setState({ index: this.state.index + 1 });
                  }
                }
              }}
              style={{ display: `${this.state.index < 5 ? "flex" : "none"}` }}
            >
              <BiArrowToBottom />
              مرحله بعد
            </button>
          )}
        </div>
      </main>
    );
  }
}

export default MyPizzaMaker;
