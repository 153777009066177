// React
import React, { Component } from "react";
// React
// Css
import styles from "./Cart.module.css";
// Css
class Cart extends Component {
  state = {};

  render() {
    const cartsArray = JSON.parse(localStorage.getItem("cartItems")) || [0];
    const priceOfAll = cartsArray.map((item) => {
      return item.price;
    });
    const totalPrice = priceOfAll.reduce((prev, curr) => {
      return prev + curr;
    });
    return (
      <div className={styles.itemsContainer}>
        <div className={styles.masterContainer}>
          {!!cartsArray[0]
            ? cartsArray.map((item) => {
                return (
                  <div key={item.customKey} className={styles.cartContainer}>
                    <div className={styles.left}>
                      <img src={item.picture} alt={item.name} />
                    </div>
                    <div className={styles.right}>
                      <div className={styles.name}>{item.name}</div>
                      <div className={styles.price}>
                        قیمت محصول : {item.price}
                      </div>
                    </div>
                  </div>
                );
              })
            : "sabad Kharid Khali e"}
        </div>

        <button>{`قیمت کلی (به تومان)  : ${
          totalPrice === undefined
            ? " 0 "
            : totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }`}</button>

        <br />
        <button onClick={() => this.forceUpdate()}>
          به روز رسانی سبد خرید{" "}
        </button>
        <br />

        <button
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          پاک کردن سبد خرید
        </button>
      </div>
    );
  }
}

export default Cart;
