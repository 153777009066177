export const localStorageCheacker = (keyOflocalStorage) => {
  if (!!localStorage.getItem(keyOflocalStorage)) {
    const selectedItem = localStorage.getItem(keyOflocalStorage);
    return JSON.parse(selectedItem);
  } else {
    return localStorage.setItem(keyOflocalStorage, "[]");
  }
};

export const addItemToLocalStorage = (keyForStoreInLocalStorage, item) => {
  const localSotrageItems =
    localStorageCheacker(keyForStoreInLocalStorage) || [];
  if (!!localStorageCheacker(keyForStoreInLocalStorage)) {
    localSotrageItems.push(item);
    const strigedData = JSON.stringify(localSotrageItems);
    return localStorage.setItem(keyForStoreInLocalStorage, strigedData);
  } else {
    return console.log("BE GA RAFTIM !!!!!!!!");
  }
};
