// React
import React, { Component } from "react";
import HeaderHelper from "../../Components/HraderHelper/HeaderHelper";
// React
// Modules
import {
  localStorageCheacker,
  addItemToLocalStorage,
} from "../../Components/MakeItemReadyForLocalStorage/MakeItemReadyForLocalStorage";
// Modules
// Images
import { d1, d2, d3, d4, d5, d6, d7, d8 } from "../../Components/Images/Images";
// Images
// CSS
import styles from "./Drinks.module.css";
// CSS
class Drinks extends Component {
  constructor(props) {
    super(props);
    this.pizzaContainerRef = React.createRef();
  }
  state = {
    drinks: [
      {
        id: "i-1",
        name: "باربیکن آناناس",
        picture: d1,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-2",
        name: "باربیکن هاوایی",
        picture: d2,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-3",
        name: "باربیکن لیمو",
        picture: d3,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-4",
        name: "باربیکن ساده",
        picture: d4,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-5",
        name: "کوکا زیرو",
        picture: d5,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-6",
        name: "کوکا ساده",
        picture: d6,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-7",
        name: "فانتا",
        picture: d7,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
      {
        id: "i-8",
        name: "اسپرایت",
        picture: d8,
        price: 40000,
        about: "عصاره میوه ی گاز دار ، آب ، قند",
      },
    ],
  };
  componentDidMount() {
    setTimeout(() => {
      this.pizzaContainerRef.current.style.opacity = 1;
      this.pizzaContainerRef.current.style.transform = "scale(1)";
    }, 200);
  }
  render() {
    return (
      <main className={styles.italianPizzaContainer}>
        <HeaderHelper drinksBold={true} />
        <div className={styles.pizzasContainer} ref={this.pizzaContainerRef}>
          <div className={styles.pizzaContainer}>
            {this.state.drinks.map((item) => {
              return (
                <div key={item.id} className={styles.pizzaModel}>
                  <div className={styles.left}>
                    <img src={item.picture} alt={item.name + "image"} />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.pizzaName}>{item.name}</div>
                    <div className={styles.innerPizza}>
                      <div>مواد تشکیل دهنده</div>
                      <div className={styles.innerContent}>{item.about}</div>
                      <div> قیمت : {item.price} تومان</div>

                      <button
                        className={styles.addToCart}
                        onClick={() => {
                          addItemToLocalStorage("drinksItems", item);
                        }}
                      >
                        افزودن به سبد خرید
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </main>
    );
  }
}

export default Drinks;
