// React
import React, { Component } from "react";
import HeaderHelper from "../../Components/HraderHelper/HeaderHelper";
// React
// CSS
import styles from "./BeforeFood.module.css";
// CSS
// Modules
import { addItemToLocalStorage } from "../../Components/MakeItemReadyForLocalStorage/MakeItemReadyForLocalStorage";
// Modules
// Images
import { bff1, bff2, bff3, bff4 } from "../../Components/Images/Images";
// Images
class BeforeFood extends Component {
  constructor(props) {
    super(props);
    this.beforeFoodRef = React.createRef();
  }
  state = {
    beforeFood: [
      {
        id: "bff-1",
        name: "پیتزای سبک یک نفره",
        about: "نان ، پنیر پیتزا ، سوسیس کوکتل ، ادویه ، سس",
        picture: bff1,
        price: 80000,
      },
      {
        id: "bff-2",
        name: "چیپس و پنیر",
        about: "سیب زمینی ، پنیر پیتزا ، ادویه",
        picture: bff2,
        price: 40000,
      },
      {
        id: "bff-3",
        name: "سیب زمینی تنوری",
        about: "سیب زمینی ، کره ، ادویه",
        picture: bff3,
        price: 20000,
      },
      {
        id: "bff-4",
        name: "سیب زمینی سرخ شده",
        about: "سیب زمینی ، نمک ، سس",
        picture: bff4,
        price: 25000,
      },
    ],
  };
  componentDidMount() {
    setTimeout(() => {
      this.beforeFoodRef.current.style.opacity = 1;
      this.beforeFoodRef.current.style.transform = "scale(1)";
    }, 200);
  }
  render() {
    return (
      <main className={styles.italianPizzaContainer}>
        <HeaderHelper beforeFoodBold={true} />
        <div className={styles.pizzasContainer} ref={this.beforeFoodRef}>
          <div className={styles.pizzaContainer}>
            {this.state.beforeFood.map((item) => {
              return (
                <div key={item.id} className={styles.pizzaModel}>
                  <div className={styles.left}>
                    <img src={item.picture} alt={item.name + "image"} />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.pizzaName}>{item.name}</div>
                    <div className={styles.innerPizza}>
                      <div>مواد تشکیل دهنده</div>
                      <div className={styles.innerContent}>{item.about}</div>
                      <div> قیمت : {item.price} تومان</div>

                      <button
                        className={styles.addToCart}
                        onClick={() => {
                          addItemToLocalStorage("beforeFood", item);
                        }}
                      >
                        افزودن به سبد خرید
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </main>
    );
  }
}

export default BeforeFood;
