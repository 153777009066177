// React
import React, { Component } from "react";
// React
// Css
import styles from "./Header.module.css";
// Css
/////React Icons
import { FaUserAlt } from "react-icons/fa";
import { FaPizzaSlice } from "react-icons/fa";
import { FaHamburger } from "react-icons/fa";
//
import { MdOutlineLocalPizza } from "react-icons/md";
import { IoIosPizza } from "react-icons/io";
import { GiPizzaSlice } from "react-icons/gi";
import { MdOutlineFastfood } from "react-icons/md";
import { BiDrink } from "react-icons/bi";
import { AiFillPhone } from "react-icons/ai";
import { FcAbout } from "react-icons/fc";
import { AiFillCloseCircle } from "react-icons/ai";
/////React Icons
// images
// images
//React Router DoM
import { Link } from "react-router-dom";
import Cart from "../Cart/Cart";

//React Router DoM
class Header extends Component {
  state = {};

  render() {
    const hamburgerMenuShow = () => {
      const hamburgerMenuContainer = document.getElementById("hamburgerMenu");
      hamburgerMenuContainer.style.visibility = "visible";
      hamburgerMenuContainer.style.opacity = "1";
      hamburgerMenuContainer.style.right = "0";
    };
    const hamburgerMenuHide = () => {
      const hamburgerMenuContainer = document.getElementById("hamburgerMenu");
      hamburgerMenuContainer.style.visibility = "hidden";
      hamburgerMenuContainer.style.opacity = "0";
      hamburgerMenuContainer.style.right = "-20vw";
    };
    return (
      <nav className={styles.navbar}>
        <div className={styles.hamburgerMenuContainer} id="hamburgerMenu">
          <button className={styles.colse} onClick={hamburgerMenuHide}>
            <AiFillCloseCircle />
          </button>
          <Link to="/my-pizza" onClick={hamburgerMenuHide}>
            سفارش پیتزای خودم <MdOutlineLocalPizza />
          </Link>
          <Link to="/america-pizza" onClick={hamburgerMenuHide}>
            پیتزای آمریکایی <IoIosPizza />{" "}
          </Link>
          <Link to="/italian-pizza" onClick={hamburgerMenuHide}>
            پیتزا ایتالیایی <GiPizzaSlice />{" "}
          </Link>
          <Link to="/before-food" onClick={hamburgerMenuHide}>
            پیش غذا <MdOutlineFastfood />{" "}
          </Link>
          <Link to="/drinks" onClick={hamburgerMenuHide}>
            نوشیدنی <BiDrink />{" "}
          </Link>
          <Link to="/my-pizzas" onClick={hamburgerMenuHide}>
            پیتزا های من <MdOutlineLocalPizza />{" "}
          </Link>
          <Link to="/contact-us" onClick={hamburgerMenuHide}>
            تماس با ما <AiFillPhone />{" "}
          </Link>
          <Link to="/about-us" onClick={hamburgerMenuHide}>
            درباره ما <FcAbout />{" "}
          </Link>
        </div>
        <div className={styles.shoppingCartItems} id="shoppingContainer">
          <Cart />
        </div>
        <Link to="/" className={styles.left}>
          <FaPizzaSlice />
        </Link>
        <div className={styles.appDownload}>دانلود اپلیکیشن ما</div>
        <div className={styles.right}>
          <div className={styles.loginBtn}>
            <FaUserAlt />
          </div>
          <div className={styles.hamburgerMenu} onClick={hamburgerMenuShow}>
            <FaHamburger />
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
