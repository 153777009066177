// React
import React, { Component } from "react";
import HeaderHelper from "../../Components/HraderHelper/HeaderHelper";
// React
// CSS
import styles from "./italianPizza.module.css";
// CSS
// Modules
import { addItemToLocalStorage } from "../../Components/MakeItemReadyForLocalStorage/MakeItemReadyForLocalStorage";
// Modules
// Images
import {
  i1,
  i2,
  i3,
  i4,
  i5,
  i6,
  i7,
  i8,
  i9,
  i10,
  i11,
} from "../../Components/Images/Images";
// Images
class ItalianPizza extends Component {
  constructor(props) {
    super(props);
    this.italianPizzaRef = React.createRef();
  }
  state = {
    italanPizza: [
      {
        id: "i-1",
        name: "فیلیتو",
        picture: i1,
        price: 40000,
        about:
          "قارچ ، پنیر موزارلا ، پنیر بلوچيز ، سس سیر ، سس گوجه فرنگی ، فیله گوساله ، چدار ، استیک ، نازک ایتالیایی",
      },
      {
        id: "i-2",
        name: "فورماجیو",
        picture: i2,
        price: 40000,
        about:
          "سس گوجه فرنگی ، قارچ ، ژامبون بوقلمون ، ژامبون ، پنیر موزارلا ، اورگانو ، چدار ، نازک ایتالیایی",
      },
      {
        id: "i-3",
        name: "چهار مزه",
        picture: i3,
        price: 40000,
        about:
          "پنیر موزارلا ، ذرت ، ژامبون بوقلمون ، فلفل دلمه‌ای ، سس گوجه فرنگی ، نازک ایتالیایی ، اسفناج ، پیاز ، زیتون سبز ، سینه مرغ گریل شده ، استیک ، زیتون سیاه ، زیتون سیاه",
      },
      {
        id: "i-4",
        name: "گوشت و قارچ",
        picture: i4,
        price: 40000,
        about: "قارچ ، پیاز ، سالامی ، گوشت قلقلی ، پنیر موزارلا",
      },
      {
        id: "i-5",
        name: "هاوایی",
        picture: i5,
        price: 40000,
        about:
          "نازک ایتالیایی ، آناناس ، ژامبون بوقلمون ، سس گوجه فرنگی ، پنیر موزارلا",
      },
      {
        id: "i-6",
        name: "مخصوص",
        picture: i6,
        price: 40000,
        about:
          "استیک ، سس گوجه فرنگی ، سالامی ، چدار ، پنیر موزارلا ، پنیر گودا ، ژامبون ، قارچ ، پنیر پارمزان ، پنیر بلوچيز ، نازک ایتالیایی",
      },
      {
        id: "i-7",
        name: "مارگاریتا",
        picture: i7,
        price: 40000,
        about: "گوجه گیلاسی ، پنیر موزارلا ، سس گوجه فرنگی ، نازک ایتالیایی",
      },
      {
        id: "i-8",
        name: "مکزیکو",
        picture: i8,
        price: 40000,
        about:
          "پیاز ، فلفل دلمه‌ای ، ذرت ، گوشت قلقلی ، سس مکزیکی ، هالوپینو ، پنیر موزارلا ، زیتون سیاه ، زیتون سیاه ، نازک ایتالیایی",
      },
      {
        id: "i-9",
        name: "پپرونی",
        picture: i9,
        price: 40000,
        about: "خمیر ، سس مکزیکی ، پپرونــی ، پنیر موزارلا",
      },
      {
        id: "i-10",
        name: "سبزیجات",
        picture: i10,
        price: 40000,
        about:
          "پیاز ، زیتون سبز ، پنیر موزارلا ، قارچ ، اسفناج ، بادمجان ، گوجه گیلاسی ، کدو ، کاپری ، نازک ایتالیایی ، فلفل رنگی ، سس گوجه فرنگی",
      },
      {
        id: "i-11",
        name: "سیسیلی",
        picture: i11,
        price: 40000,
        about:
          "نازک ایتالیایی ، ژامبون ، پپرونی ، پنیر موزارلا ، سوسیس پنیری ، فلفل دلمه‌ای ، زیتون سیاه",
      },
    ],
  };
  componentDidMount() {
    setTimeout(() => {
      this.italianPizzaRef.current.style.opacity = 1;
      this.italianPizzaRef.current.style.transform = "scale(1)";
    }, 200);
  }
  render() {
    return (
      <main className={styles.italianPizzaContainer}>
        <HeaderHelper italianBold={true} />
        <div className={styles.pizzasContainer} ref={this.italianPizzaRef}>
          <div className={styles.pizzaContainer}>
            {this.state.italanPizza.map((item) => {
              return (
                <div key={item.id} className={styles.pizzaModel}>
                  <div className={styles.left}>
                    <img src={item.picture} alt={item.name + "image"} />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.pizzaName}>{item.name}</div>
                    <div className={styles.innerPizza}>
                      <div>مواد تشکیل دهنده</div>
                      <div className={styles.innerContent}>{item.about}</div>
                      <div> قیمت : {item.price} تومان</div>

                      <button
                        className={styles.addToCart}
                        onClick={() => {
                          addItemToLocalStorage("italianPizza", item);
                        }}
                      >
                        افزودن به سبد خرید
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </main>
    );
  }
}

export default ItalianPizza;
